import React from 'react'

const DefaultHelmet: React.FC = () => (
  <>
    <meta name="author" content="Daniel Penagos" />
    <meta name="copyright" content="Daniel Penagos" />
    <meta name="robots" content="index" />
    <meta name="robots" content="follow" />
  </>
)

export default DefaultHelmet
